<style scoped>
@import "~@fortawesome/fontawesome-free/css/all.css";
@import "~bootstrap/dist/css/bootstrap.css";
@import "~bootstrap-vue/dist/bootstrap-vue.css";
@import "~vue-toast-notification/dist/theme-sugar.css";
@import "~pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";
</style>
<style>
#exerciceModal .modal-content,
#exerciceModal .modal-header {
  border-radius: 0;
}
#exerciceModal .modal-header{
  text-align: center;
}
</style>
<template id="main-app">
  <div class="page-wrapper chiller-theme toggled">
    <a
      id="show-sidebar"
      class="btn btn-sm btn-light text-left"
      href="javascript:void(0)"
      style="border-radius: 0px; color: brown"
    >
      <i class="fas fa-bars"></i>
    </a>
    <nav id="sidebar" class="sidebar-wrapper">
      <div class="sidebar-content">
        <div class="sidebar-brand">
          <a
            href="javascript:void(0)"
            style="color: #363636; font-size: 1.8rem; letter-spacing: 2px"
          >
            <img
              class="img-responsive"
              src="../public/assets/img/activite-plus.png"
              alt="Logo"
              style="max-width: 100%; height: auto"
            />
            <!-- :src="logo_soc==''?'../public/assets/img/Logo-Tsaravidy.jpg':BASE_URL+'/public/assets/img/'+logo_soc" -->
          </a>
          <div id="close-sidebar">
            <i class="fas fa-bars"></i>
          </div>
        </div>
        <div class="sidebar-header" style="margin-top: 90px">
          <div class="user-pic">
            <img
              class="img-responsive img-rounded"
              src="../public/assets/img/user.png"
              alt="User picture"
            />
          </div>
          <div class="user-info">
            <span class="user-name">
              <strong>{{ nom.replace("+", " ") }}</strong>
            </span>
            <span class="user-role">{{ role }}</span>
            <span class="user-status" style="cursor: pointer">
              <i class="fa fa-caret-right"></i>
              <span
                ><a
                  href="javascript:void(0)"
                  @click="doLogout"
                  style="color: #888; cursor: pointer"
                >
                  Se deconnecter</a
                ></span
              >
            </span>
          </div>
        </div>

        <div class="sidebar-menu">
          <ul id="nav">
            <li class="header-menu">
              <span>{{ $t("nav_menu.home") }}</span>
            </li>
            <li>
              <router-link to="/dashboard">
                <i class="fa fa-users"></i>
                <span>{{ $t("nav_menu.dashboard") }}</span>
              </router-link>
            </li>

            <li class="dropdown">
              <a href="javascript:void(0)" class="menu-group">
                Trésoreries
                <span class="indice">
                  <i class="fa fa-angle-up"></i>
                </span>
              </a>
              <ul class="open">
                <li>
                  <router-link to="/tresoreries">
                    <i class="fas fa-angle-right"></i>
                    <span>Liste des trésoreries</span>
                  </router-link>
                </li>
                <li>
                  <router-link to="/tresoreriesmouvement">
                    <i class="fas fa-angle-right"></i>
                    <span>Journal trésorerie</span>
                  </router-link>
                </li>
                <li>
                  <router-link to="/tresoreriesflux">
                    <i class="fas fa-angle-right"></i>
                    <span>Flux de trésorerie</span>
                  </router-link>
                </li>
              </ul>
            </li>

            <li class="dropdown">
              <a href="javascript:void(0)" class="menu-group">
                Etats
                <span class="indice">
                  <i class="fa fa-angle-up"></i>
                </span>
              </a>
              <ul class="open">
                <li>
                  <router-link to="/ca">
                    <i class="fas fa-angle-right"></i>
                    <span>Chiffre d'affaire</span>
                  </router-link>
                </li>
                <li>
                  <router-link to="/etatfinancement">
                    <i class="fas fa-angle-right"></i>
                    <span>Etat financement</span>
                  </router-link>
                </li>
              </ul>
              
            </li>

            <li class="dropdown">
              <a href="javascript:void(0)" class="menu-group">
                Paramètres
                <span class="indice">
                  <i class="fa fa-angle-up"></i>
                </span>
              </a>
              <ul class="open">
                <li>
                  <router-link to="/activites">
                    <i class="fas fa-angle-right"></i>
                    <span>Liste des activités</span>
                  </router-link>
                </li>
                <li>
                  <router-link to="/exercices">
                    <i class="fas fa-angle-right"></i>
                    <span>Exercices</span>
                  </router-link>
                </li>

              </ul>
            </li>

            <li class="dropdown">
              <a href="javascript:void(0)" class="menu-group">
                Utilisateurs
                <span class="indice">
                  <i class="fa fa-angle-up"></i>
                </span>
              </a>
              <ul class="open">
                <li>
                  <router-link to="/utilisateurs">
                    <i class="fas fa-angle-right"></i>
                    <span>Liste des utilisateur</span>
                  </router-link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <!-- sidebar-menu  -->
      </div>
      <!-- sidebar-content  -->
    </nav>

    <!-- sidebar-wrapper  -->
    <main class="page-content">
      <div class="all-content">
        <header>
          <nav class="navbar navbar-expand-lg navbar-light bg-light">
            <button
              class="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
            <a class="navbar-brand">
              <i class="fas fa-store-alt"></i>
              <span class="ml-1" style="cursor: pointer" v-if="users_activeplus_activites_nom!='' && users_activeplus_activites_nom!=null" @click="openActiviteModal">{{users_activeplus_activites_nom.replace("+", " ").replace("+", " ")}}</span>
              <span style="cursor: pointer" v-else class="text-danger ml-1" @click="openActiviteModal">Choisir une activité</span> - 
              <span v-if="current_exercice!=''" class="text-bold mr-1" @click="openExerciceModal" style="text-decoration: underline; cursor: pointer">{{ current_exercice.replace("+", " ").replace("+", " ") }}</span>
              <span v-else class="text-bold mr-1 text-danger" @click="openExerciceModal" style="text-decoration: underline; cursor: pointer">Choisir un exercice</span>
              <i class="fas fa-angle-right"></i>
              <!--strong> Tsaravidy Morafeno</strong> &nbsp;<i
                class="fas fa-angle-right"
              ></i-->
              <span> {{ titre }}</span>
            </a>
            <div class="collapse navbar-collapse" id="navbarNav">
              <ul class="navbar-nav mr-auto"></ul>
              <ul class="navbar-nav">
                
                <li class="nav-item dropdown">
                  <a
                    class="btn btn-light dropdown-toggle color-newrest-gris"
                    title="Paramètres utilisateurs"
                    type="button"
                    data-toggle="dropdown"
                    href="javascript:void(0);"
                    role="button"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i class="fas fa-users-cog fa-2x"></i>
                  </a>
                  <div
                    class="dropdown-menu dropdown-menu-right"
                    aria-labelledby=""
                  >
                    <a class="dropdown-item" href="#"
                      ><i class="fas fa-user"></i> Mon profil</a
                    >
                    <!-- <a class="dropdown-item" href="#"
                      ><i class="fas fa-users-cog"></i> Groupe d'utilisateur</a
                    > -->
                    <!-- <router-link class="dropdown-item" to="/utilisateurs"
                      ><i class="fas fa-users"></i> Utilisateur du
                      logiciel</router-link
                    > -->
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" @click="doLogout"
                      ><i class="fas fa-sign-out-alt"></i> Se déconnecter</a
                    >
                  </div>
                </li>
              </ul>
            </div>
          </nav>
        </header>

        <div class="container-fluid">
          <router-view @change-page="onChangePage" />
        </div>
        <div v-if="showLoading" class="dna-loader">
          <img :src="base_url + '/public/assets/img/loading.gif'" />
        </div>
      </div>
    </main>
    <!-- page-content" -->

    <b-modal
      id="exerciceModal"
      title="Choisir un exercice"
      size="lg"
      hide-footer
    >
              <!-- current_exercice -->

      <div class="text-center">
        <span v-for="o in exercices" :key="o.id">
          <button type="button" :class="'btn btn-lg p-5 m-2 ' + (o.nom_exercice==current_exercice?'btn-secondary':'btn-outline-secondary')" @click="choisirExercice(o.id)">{{o.nom_exercice}}</button>
        </span>
      </div>
    </b-modal>

    <b-modal
      id="activitesModal"
      title="Choisir une activité"
      size="lg"
      hide-footer
    >
              <!-- current_exercice -->

      <div class="text-center">
        <div class="row">
          <!-- {{activites}} -->
          <div class="col-md-6" v-for="o in activites" :key="o.id">
            <button type="button" :class="'btn btn-lg p-5 btn-block mb-3 ' + (o.id==users_activeplus_activites_id?'btn-secondary':'btn-outline-secondary')" @click="choisirActivite(o)">{{o.nom}}</button>
          </div>
        </div>
        <!-- <span v-for="o in activites" :key="o.id">
          
        </span> -->
      </div>
    </b-modal>
  </div>
</template>
  
<script>
// const axios = () => import ("axios");

import axios from "axios";
import $ from 'jquery'
export default {
  data: function () {
    return {
      titre: "",
      showLoading: false,
      nom: "",
      role: "",
      id_user: "",
      logo_soc: "",
      base_url: this.BASE_URL,
      current_exercice: "",
      current_activites: [],
      exercices : [],
      activites: [],
      users_activeplus_activites_nom: "",
      users_activeplus_activites_id: ""
    };
  },
  methods: {
    onChangePage: function (pageTitle) {
      this.titre = pageTitle.replace("+", " ");
      console.log(this.titre);
    },
    onChangeLoad: function (status, userData) {
      // console.log("LOADING");
      if (status == "loading") {
        this.showLoading = true;
        console.log("LOADING");
      } else {
        this.showLoading = false;
        console.log("LOADED");
      }
      // console.log("userData");
      // console.log(userData);
      this.nom = userData.users_activeplus_nom;
      this.role= userData.users_activeplus_role;
      this.logo_soc = userData.users_activeplus_logo;
      this.id_user = userData.users_activeplus_id;
      this.current_exercice = userData.users_activeplus_exercice;
      this.current_activites = userData.users_activeplus_activites;
      this.users_activeplus_activites_nom = userData.users_activeplus_activites_nom;
      this.users_activeplus_activites_id = userData.users_activeplus_activites_id;

      // if(window.matchMedia("only screen and (max-width: 760px)").matches == true) {
      //   $(".page-wrapper").removeClass("toggled");
      // }

      if (
        window.matchMedia("only screen and (max-width: 760px)").matches == true
      ) {
        $(".page-wrapper").removeClass("toggled");
      } 
      else {
        document.body.style.zoom = 0.85;
      }

    },
    doLogout: function () {
      var that = this;
      axios.get(this.BASE_URL + "/users/logout").then(function () {
        that.$router.push("/login");
      });
    },
    openActiviteModal() {
      var that = this;
      axios.get(this.BASE_URL + "/activites/getall").then(function (response) {
        that.activites = response.data;
        that.$bvModal.show("activitesModal");
      });
    },
    openExerciceModal() {
      var that = this;
      axios.get(this.BASE_URL + "/exercices/getall").then(function (response) {
        that.exercices = response.data;
      });
      this.$bvModal.show("exerciceModal");
    },
    closeExerciceModal() {
      this.$bvModal.hide("exerciceModal");
    },
    choisirExercice($id_exercice){
      var that = this;
      axios.get(this.BASE_URL + "/exercices/choisirexercice/"+$id_exercice).then(function (response) {
        that.current_exercice = response.data.users_activeplus_exercice;
        that.closeExerciceModal();
        location.reload();
      });
    },
    choisirActivite(activite){
      this.current_activites = activite;
      var that = this;
      axios.get(this.BASE_URL + "/activites/choisiractivites/"+activite.id).then(function (response) {
        that.current_activites = response.data.users_activeplus_activites;
        that.$bvModal.hide("activitesModal");
        location.reload();
      });
    }
  },
  created: function () {
    this.$parent.$on("change-load", this.onChangeLoad);
  },
  computed: {
    nom_user() {
      if (this.nom == "" || this.nom == null) {
        return "";
      }
      return this.nom.replace("+", " ").replace("+", " ").replace("+", " ");
    },
    role_user() {
      if (this.role == "" || this.role == null) {
        return "";
      }
      return this.role.replace("+", " ").replace("+", " ").replace("+", " ");
    },

  }
};
</script>
<style>
</style>