import Vue from 'vue'
import './plugins/fontawesome'
import App from './App.vue'
import Login from './views/Login'

import '@fortawesome/fontawesome-free/js/all.js'
import VueCurrencyInput from 'vue-currency-input'
import VueNumeric from 'vue-numeric'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import datePicker from 'vue-bootstrap-datetimepicker'
import VueToast from 'vue-toast-notification'
import axios from 'axios'
import i18n from './i18n'
import VueCookies from 'vue-cookies'
// import VueNativeSock from 'vue-native-websocket'

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(datePicker)
Vue.use(VueToast)
Vue.use(VueCookies)


if (process.env.NODE_ENV == 'development') {
    Vue.BASE_URL = Vue.prototype.BASE_URL = '';
} else { // Production
    // Vue.BASE_URL = Vue.prototype.BASE_URL = '/Tsaravidy_activites/tsaravidy_activites';
    Vue.BASE_URL = Vue.prototype.BASE_URL = '';
}
Vue.UNITE = Vue.prototype.UNITE = '';

import router from './router'

Vue.config.productionTip = false

const pluginOptions = {
    globalOptions: { currency: null }
};

Vue.use(VueCurrencyInput, pluginOptions);
Vue.use(VueNumeric);

axios.get(Vue.BASE_URL + "/users/getuserconfig").then(function() {})

var user = {
    users_activeplus_id: Vue.$cookies.get("users_activeplus_id"),
    users_activeplus_role: Vue.$cookies.get("users_activeplus_role"),
    users_activeplus_nom: Vue.$cookies.get("users_activeplus_nom"),
    users_activeplus_statut: Vue.$cookies.get("users_activeplus_statut"),
    users_activeplus_exercice: Vue.$cookies.get("users_activeplus_exercice"),
    users_activeplus_exercices_id: Vue.$cookies.get("users_activeplus_exercices_id"),
    users_activeplus_activites: Vue.$cookies.get("users_activeplus_activites"),
    users_activeplus_activites_nom: Vue.$cookies.get("users_activeplus_activites_nom"),
    users_activeplus_activites_id: Vue.$cookies.get("users_activeplus_activites_id"),
};

var vm = null;
if (user.users_activeplus_id == '' || user.users_activeplus_id == null || user.users_activeplus_id == undefined) {
    vm = new Vue({
        router,
        render: h => h(Login),
        i18n
    }).$mount('#app');
} else {
    vm = new Vue({
        router,
        render: h => h(App, {
            props: {
                users_info: user
            }
        }),
        i18n
    }).$mount('#app');
}






router.beforeEach((to, from, next) => {
    axios.get(Vue.BASE_URL + "/users/getuserconfig").then(function() {});
    var user = {
        users_activeplus_id: Vue.$cookies.get("users_activeplus_id"),
        users_activeplus_role: Vue.$cookies.get("users_activeplus_role"),
        users_activeplus_nom: Vue.$cookies.get("users_activeplus_nom"),
        users_activeplus_statut: Vue.$cookies.get("users_activeplus_statut"),
        users_activeplus_exercice: Vue.$cookies.get("users_activeplus_exercice"),
        users_activeplus_exercices_id: Vue.$cookies.get("users_activeplus_exercices_id"),
        users_activeplus_activites: Vue.$cookies.get("users_activeplus_activites"),
        users_activeplus_activites_nom: Vue.$cookies.get("users_activeplus_activites_nom"),
        users_activeplus_activites_id: Vue.$cookies.get("users_activeplus_activites_id"),
    };
    vm.$emit('change-load', 'loading', user);
    if (user.users_activeplus_id == '' ||
        user.users_activeplus_id == null ||
        user.users_activeplus_id == undefined
    ) {
        location.reload();
    } else {
        next();
    }

})
router.afterEach(() => {
    var user = {
        users_activeplus_id: Vue.$cookies.get("users_activeplus_id"),
        users_activeplus_role: Vue.$cookies.get("users_activeplus_role"),
        users_activeplus_nom: Vue.$cookies.get("users_activeplus_nom"),
        users_activeplus_statut: Vue.$cookies.get("users_activeplus_statut"),
        users_activeplus_exercice: Vue.$cookies.get("users_activeplus_exercice"),
        users_activeplus_exercices_id: Vue.$cookies.get("users_activeplus_exercices_id"),
        users_activeplus_activites: Vue.$cookies.get("users_activeplus_activites"),
        users_activeplus_activites_nom: Vue.$cookies.get("users_activeplus_activites_nom"),
        users_activeplus_activites_id: Vue.$cookies.get("users_activeplus_activites_id"),
    };
    vm.$emit('change-load', 'loading', user);
    vm.$emit('change-load', 'loaded', user);
})
console.log(user);
vm.$emit('change-load', 'loading', user);
vm.$emit('change-load', 'loaded', user);
// });